



























import { mapGetters } from 'vuex';
import Vue from 'vue';
import { Route } from '@/model/enums/route';

export default Vue.extend({
  name: 'UserMenu',
  data() {
    return {
      defaultImage: '/images/default-user-image.png',
    };
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUserStorage/getCurrentUser' }),
  },
  methods: {
    logout() {
      this.$store.dispatch('currentUserStorage/logout');
      this.$router.push({ name: Route.LOGIN });
    },
  },
});
