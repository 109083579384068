







import Vue from 'vue';

export default Vue.extend({
  name: 'MaximLogo',
  props: {
    loginForm: Boolean,
  },
  data() {
    return {
      logoImage: '/images/maxim-logo.svg',
    };
  },
});
