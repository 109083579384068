import { render, staticRenderFns } from "./MaximLogo.vue?vue&type=template&id=121baec5&scoped=true&"
import script from "./MaximLogo.vue?vue&type=script&lang=ts&"
export * from "./MaximLogo.vue?vue&type=script&lang=ts&"
import style0 from "./MaximLogo.vue?vue&type=style&index=0&id=121baec5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "121baec5",
  null
  
)

export default component.exports