








import Vue from 'vue';
import UserMenu from '@/components/Menubar/UserMenu.vue';
import MaximLogo from '@/components/Menubar/MaximLogo.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'TopMenu',
  components: {
    UserMenu,
    MaximLogo,
    SearchBar,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'currentUserStorage/isLoggedIn',
    }),
  },
});
